import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import Typography from 'components/uiLibrary/Typography';
import SectionLink from 'components/Globals/SectionLink';
import EntityName from 'components/Globals/EntityName';

import { getEntityPerformedDates } from 'utils/productions';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import { TP, TRACK_CASTING_TOOL_IMPRESSION, ENTITY_TYPE } from 'constants/index';
import classnames from 'classnames';

import ProductionPeriod from '../ProductionPeriod';
import DatesCount from '../DatesCount';
import classes from './LocationAndDates.module.scss';
import { VenueContent } from '../../VenuesListing/VenuesListing';
import DatesList from '../../VenuesListing/DatesList';

const ExpandedSection = ({ venuesWithDates, profile, production, showTitle, isClash, trackingData }) => {
  const { t } = useTranslation('NS_CASTING_TOOL');

  const shouldShowYear = ({ datesIndex, venue, year, yearOfLastVenue, yearOfLastDateGroup }) => {
    if (datesIndex === 0) {
      return isEmpty(venue) ? true : year !== yearOfLastVenue;
    }
    return year !== yearOfLastDateGroup;
  };

  return (
    <div className={classnames(classes.expandedSection, { [classes.quickPreview]: !!showTitle })}>
      <div className={classes.expandedSection__title}>
        <Typography color="secondary" size="12">
          <EntityName
            entityType={ENTITY_TYPE.ARTIST}
            entity={{ id: profile?.id, name: profile?.name }}
            eventType={TRACK_CASTING_TOOL_IMPRESSION(ENTITY_TYPE.ARTIST)}
            trackingData={trackingData}
            isRaw
          />{' '}
          {t(`${TP}.FN_ARTIST_PERFORMED_DATES`)}
        </Typography>
      </div>
      <div className={classes.expandedSection__content}>
        <ul className={classes.venueItems}>
          {venuesWithDates?.map(({ venue, dates }, index) => (
            <li className={classes.venueItem} key={`${venue?.id}-${venue?.id}-${index}`}>
              <VenueContent
                key={`${venue?.id}-${index}`}
                venue={venue}
                showHeader={venue?.city?.id !== venuesWithDates?.[index - 1]?.venue?.city?.id}
                isProSlug
                trackingData={trackingData}
              />

              {!isClash && (
                <div key={`${venue?.id}-${index}-${Object.keys(dates)?.length}`} className={classes.datesList}>
                  {Object.keys(dates)?.map((monthYear, datesIndex) => {
                    const [month, year] = monthYear.split(' ');
                    const yearOfLastDateGroup = Object.keys(dates)?.[datesIndex - 1]?.split?.(' ')?.[1];
                    const datesOfLastVenue = Object.keys(venuesWithDates?.[index - 1]?.dates || {});
                    const yearOfLastVenue = datesOfLastVenue?.[datesOfLastVenue?.length - 1]?.split?.(' ')?.[1];
                    const showYear = shouldShowYear({
                      datesIndex,
                      venue,
                      year,
                      yearOfLastVenue,
                      yearOfLastDateGroup,
                    });
                    return (
                      <DatesList
                        key={`${monthYear}-${datesIndex}`}
                        year={year}
                        month={month}
                        dates={dates[monthYear]}
                        isCancelled={production?.isCancelled}
                        shouldShowYear={showYear}
                        showCalendarIcon={isEmpty(venue) && showYear}
                        entity={production}
                        isProSlug
                        className={classes.datesList__item}
                        trackingData={trackingData}
                      />
                    );
                  })}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const LocationAndDates = ({
  isExpanded = false,
  showCollapsedAlways = true,
  production,
  profile,
  isHovered,
  showTitle,
  hideProductionPeriod = false,
  trackingData,
}) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const { entity, entityType } = usePageContext();
  const { groupedData, artistPerformedDates, isClash } = useMemo(
    () => getEntityPerformedDates(production, entity?.id || profile?.id, entityType),
    [production, entity?.id, profile?.id, entityType],
  );

  const city = groupedData?.[0]?.venue?.city?.name;
  const title = useMemo(() => {
    if (isClash) {
      return '';
    }
    if (artistPerformedDates === production?.dates?.length) {
      return t(`${TP}.FN_ALL_DATES`);
    }
    return t(`${TP}`.FN_TOTAL_PERFORMED_DATES_COUNT, {
      performed: artistPerformedDates,
      total: production?.dates?.length,
    });
  }, [isClash, artistPerformedDates, production]);

  if (!showCollapsedAlways && !showTitle && !isExpanded) {
    return null;
  }
  return (
    <div className={classes.parent}>
      {showCollapsedAlways && (
        <div className={classes.collapseSection}>
          <div className={classes.location}>
            {city && (
              <Typography
                color={isExpanded || isHovered ? 'primary' : 'territory'}
                size="12"
                truncate
                className={classes.countryName}
              >
                {groupedData?.length === 1 ? city : `${city}, ...`}
              </Typography>
            )}
            <DatesCount production={production} profile={profile} isExpanded={isExpanded} isHovered={isHovered} />
          </div>
          {!hideProductionPeriod && (
            <ProductionPeriod
              production={production}
              profile={profile}
              isHovered={isHovered}
              trackingData={trackingData}
            />
          )}
        </div>
      )}
      {showTitle && <SectionLink title={title} />}
      {isExpanded && (
        <ExpandedSection
          venuesWithDates={groupedData}
          profile={profile}
          production={production}
          showTitle={showTitle}
          isClash={isClash}
          trackingData={trackingData}
        />
      )}
    </div>
  );
};

export default LocationAndDates;
