import React from 'react';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Link from 'components/uiLibrary/Link'; // TODO: Change to use LinkButton
import Typography from 'components/uiLibrary/Typography';

import { useTranslation } from 'src/i18n';
import { TP } from 'constants/index';

import classes from './SectionLink.module.scss';

const Title = ({
  title,
  count,
  linkProps,
  variant = 'h2',
  weight = 'bold',
  className,
  titleProps,
  withoutMargin,
  asFooterLink = false,
  titleAttribute,
  isEmbedded,
}) => {
  const titleElement = (
    <Typography
      variant={asFooterLink ? 'span' : variant}
      className={classnames(classes.title, {
        [classes.footerTitle]: asFooterLink,
        [className]: className,
        [classes.withoutMargin]: withoutMargin,
        [classes.title__link]: linkProps,
      })}
      weight={asFooterLink ? 'regular' : weight}
      size={asFooterLink ? 12 : 16}
      {...titleProps}
    >
      {title}
      {count > 0 && (
        <Typography color="secondary" className={classes.count} size="14" weight="medium">{` ${count}`}</Typography>
      )}
      {linkProps && <SpriteIcon icon="navigate_next" size={24} />}
    </Typography>
  );

  if (linkProps) {
    return (
      <Link {...linkProps} disableUnderline={!asFooterLink} title={titleAttribute} external={isEmbedded} scroll>
        {titleElement}
      </Link>
    );
  }

  return titleElement;
};

const SeeAll = ({ linkProps, asFooterLink, enableSeeAll }) => {
  const { t } = useTranslation('NS_DISPLAY_V4');

  if (enableSeeAll && linkProps) {
    return (
      <Link {...linkProps} disableUnderline={!asFooterLink} scroll>
        {t(`${TP}.m_ARTICLES_SEE_ALL`)}
      </Link>
    );
  }

  return null;
};

const SectionLink = ({ title, linkProps, asFooterLink, enableSeeAll = false, withoutIndicator = false, ...rest }) => {
  if (!title) {
    return null;
  }

  return (
    <div className={classes.sectionLinkWrapper}>
      <div className={classes.titleWrapper}>
        {!asFooterLink && !withoutIndicator && <div className={classes.indicator} />}
        <Title title={title} linkProps={asFooterLink ? linkProps : null} asFooterLink={asFooterLink} {...rest} />
      </div>
      <SeeAll linkProps={linkProps} enableSeeAll={enableSeeAll} />
    </div>
  );
};

export default SectionLink;
