import React, { useMemo } from 'react';

import Typography from 'components/uiLibrary/Typography';
import Tooltip from 'components/uiLibrary/Tooltip';
import { useTranslation } from 'src/i18n';

import { getEntityPerformedDates } from 'utils/productions';
import { TP } from 'constants/index';
import classes from './DatesCount.module.scss';

const DatesCount = ({ production, profile, isExpanded, isHovered }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { artistPerformedDates, total, isClash } = useMemo(() => getEntityPerformedDates(production, profile?.id), [
    production,
    profile?.id,
  ]);

  if (isClash) {
    return null;
  }

  const title = `${artistPerformedDates} of ${total} ${t(`${TP}.m_DATES`)}`;

  return (
    <Tooltip title={title} className={classes.toolTip}>
      <div className={classes.text}>
        <Typography size={12} color={isExpanded || isHovered ? 'primary' : 'tertiary'} className={classes.dates}>
          ({artistPerformedDates} / {total})
        </Typography>
      </div>
    </Tooltip>
  );
};

export default DatesCount;
